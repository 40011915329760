<template>
	<b-card class="mb-1 rounded-0 border-0">
		<div class="d-flex align-items-center justify-content-between collapse-header">
			<div class="d-flex align-items-center">
				<h6 class="mb-0 font-weight-bold">Študent {{ index || '' }}</h6>
			</div>
			<div v-if="isCourse && enoughDatesSelected && !isGamecraftOnSchools">{{ orderRegistration.order_registration_dates.length }} lekcií / {{ orderRegistration.price }}€</div>
		</div>
		<div v-if="enoughDatesSelected">
			<div v-if="!isGamecraftOnSchools" class="d-flex justify-content-between border-bottom mt-2 py-2">
				<div v-if="isCourse">
					Počet objednaných kurzov:
				</div>
				<div v-else>
					Počet objednaných {{ isCourse ? 'lekcií' : 'turnusov' }}:
					<z-tooltip v-if="type == 'gamecraft'" text="Čím viac zadáte termínov v kalendári, tým získate výhodnejšiu cenu." />
				</div>
				<div v-if="isCourse" class="font-weight-bold">
					{{ orderRegistration.order_registration_dates.length }}
				</div>
				<div v-else class="font-weight-bold">{{ turnusesCount }}</div>
			</div>
			<div v-if="isCourse && type != 'gamisfera-licencia' && !isGamecraftOnSchools && type != 'skyro'" class="d-flex justify-content-between border-bottom py-2">
				<div>Cena za lekciu:</div>
				<div class="font-weight-bold">{{ orderRegistration.lecture_price }}€</div>
			</div>
			<div class="d-flex justify-content-between pt-2">
				<div>Hodnota objednávky:</div>
				<div class="font-weight-bold">{{ orderPrice }}€</div>
			</div>
		</div>
		<div v-else>
			<div class="d-flex justify-content-between	border-bottom mt-2 py-2">
				<div>
					Počet dní nutných na pokračovanie:
					<span class="font-weight-bold">{{ howManyOrdersNeededTillMinimum }}</span>
				</div>
				<z-tooltip :text="'Minimálny počet termínov: ' + this.orderRegistration.product_period.product.minimum_selected_days" />
			</div>
		</div>
	</b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import productUtils from '@/plugins/app/_utils/product.util.js'

export default {
	components: {
		'z-tooltip': () => import('@/plugins/app@components/tooltip/z-tooltip.vue')
	},

	props: {
		orderRegistration: {
			type: Object,
			required: true
		},
		index: {
			type: [Number, String],
			required: true
		}
	},

	computed: {
		...mapGetters('order', {
			orderRegistrationsForStudentIndexWithPrices: 'orderRegistrationsForStudentIndexWithPrices',
			ordertotalDaysValidation: 'totalDaysValidation'
		}),

		orderPrice() {
			return this.orderRegistrationsForStudentIndexWithPrices(this.orderRegistration.fe_custom_data.student_index).reduce((price, or) => price + or.price, 0)
		},

		turnusesCount() {
			return this.orderRegistrationsForStudentIndexWithPrices(this.orderRegistration.fe_custom_data.student_index).length
		},

		type() {
			return this.$route.params.type
		},

		enoughDatesSelected() {
			return this.howManyOrdersNeededTillMinimum <= 0
		},

		howManyOrdersNeededTillMinimum() {
			if (!this.orderRegistration.product_period) return -1

			if (!this.orderRegistration.order_registration_dates) return this.orderRegistration.product_period.product.minimum_selected_days

			return this.orderRegistration.product_period.product.minimum_selected_days - this.orderRegistration.order_registration_dates.length
		},

		isCourse() {
			return productUtils.isCourse(this.type)
		},

    isGamecraftOnSchools() {
      return this.type === 'gamecraft-na-skolach' || this.type === 'gamecraft-na-skolach-60min'
    }
	}
}
</script>

<style lang="sass" scoped>
.card
	border: 2px solid #fff !important
	&.active
		background-color: rgba(118, 173, 99, 0.25)
		color: #000
		border: 2px solid #76AD63 !important

.info-btn
	font-size: 13px
	line-height: 1
	margin-top: -12px
</style>
